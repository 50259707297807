@import  "../../App.scss";


.dashboard-container1 {
    display: flex;               /* Use flexbox for centering */
    justify-content: center;     /* Center horizontally */
    align-items: center;         /* Center vertically */
    height: 100vh;               /* Full height of the viewport */
  }
  
  .card-container1 {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 50%;
    width: 100%;                /* Make the card responsive */
    margin: 20px;               /* Add margin for spacing */
  }
  
  .form {
    text-align: center;
  }
  
  h2 {
    font-size: 1.5em;
    color: #5B5FC7; // Your custom purple color
  }
  
  .button-container {
    text-align: center;
  }
  
  .purple-button {
    background-color: #5B5FC7 !important;
    color: white !important;
  }
  
  .purple-button:hover {
    background-color: #4949b5 !important; // Slightly darker shade for hover
  }
  