@import "../App.scss";


.home-container{
    .box-head{
        margin: 2% 3% !important;
        .box-content{
            /* Created with https://www.css-gradient.com */
            background: #343434;
            background: -webkit-linear-gradient(bottom right, #343434, #656565);
            background: -moz-linear-gradient(bottom right, #343434, #656565);
            background: linear-gradient(to top left, #343434, #656565);
            text-align: center;
            padding: 4% 2%;
            border-radius: 10px;
            h2{
                font-size: 4em;
                color: white;
                font-weight: 500;
                color: #8c9cff
            }
            h3{
                color: white;
                font-weight: 300;
            }
            
        }

          
        
    }
    
}

.notice-container {
    background-color: #fff4e6; /* Light beige background */
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ffdab3; /* Slightly darker border */
    color: #8b3c2e; /* Darker red-brown color for text */
    font-family: 'Arial', sans-serif;
    // max-width: 600px;
    margin: 2% 2% 2% 0;
  }
  
  .notice-container h2 {
    color: #8b3c2e; /* Same color as text */
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .notice-container p {
    font-size: 16px;
    line-height: 1.6;
  }

  .profile-graph{
    display: flex;
    justify-content: end;
  }


  .user-info-card {
    background-color: #ffffff; /* Pure white background */
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Larger shadow for depth */
    max-width: 600px;
    margin: 30px auto; /* Center card */
    font-family: 'Roboto', sans-serif;
    
  
    h1 {
      font-size: 22px; /* Smaller font size for heading */
      color: #5b5fc7; /* Custom purple */
      text-align: center;
      margin-bottom: 20px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }
  
    .user-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
  
      .user-detail {
        display: flex;
        flex-direction: column;
        background-color: #f4f4f4;
        padding: 15px;
        border-radius: 8px;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #e1e1e1;
        }
  
        strong {
          font-size: 12px; /* Smaller heading for detail */
          color: #666666; /* Light gray color */
          margin-bottom: 5px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }
  
        span {
          font-size: 18px; /* Slightly smaller data text */
          color: #333333;
          font-weight: 500;
        }
      }
    }
  }

  .insights-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 30px 0;
    
    .insight-box {
      background-color: #ffffff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      flex: 1 1 calc(25% - 20px);
      max-width: calc(25% - 20px);
      text-align: center;
      font-family: 'Roboto', sans-serif;
      
      h3 {
        font-size: 16px;
        color: #5b5fc7;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      
      .amount {
        font-size: 24px;
        color: #333333;
        font-weight: 600;
        margin: 10px 0;
      }
      
      .cases {
        font-size: 14px;
        color: #777777;
      }
    }
    
    @media (max-width: 768px) {
      .insight-box {
        flex: 1 1 calc(50% - 20px);
        max-width: calc(50% - 20px);
      }
    }
  
    @media (max-width: 480px) {
      .insight-box {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
  }
  
  